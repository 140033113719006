/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import moment from 'moment';
import { equals } from 'ramda';
import useValidator from '../../../hooks/useValidator';
import { Input, Select, DateInput, Button, Icon, PhoneNumberInput } from '../..';
import { AuthUser, Gender, Sex } from '../../../context/authContext';
import customValidators from '../../../utils/customValidators';

type AccountFormProps = {
  account: AuthUser;
  isUpdating: boolean;
  isCompany: boolean;
  someFieldsNeeded: boolean;
  onUpdateAccount: (payload: Partial<AuthUser>) => void;
  onImageUpload: (type: 'ID') => void;
};

function AccountDetailsForm({
  account,
  onUpdateAccount,
  someFieldsNeeded,
  onImageUpload,
  isUpdating,
  isCompany,
}: AccountFormProps) {
  const [dateInputType, setDateInputType] = React.useState('text');
  const [validator, showValidationMessage] = useValidator({}, customValidators);
  const [hasFieldsChange, setHasFieldsChange] = React.useState(false);
  const [errors, setErrors] = React.useState({
    firstName: false,
    lastName: false,
    email: false,
    dateOfBirth: false,
    gender: false,
    genderIdentity: false,
    preferredName: false,
    preferredPronouns: false,
    ftHeight: false,
    inHeight: false,
    weight: false,
    eFirstName: false,
    eLastName: false,
    ePhoneNumber: false,
    idPhoto: false,
  });
  const [accDetails, setAccDetails] = React.useState({
    firstName: account.firstName,
    lastName: account.lastName,
    email: account.email,
    dateOfBirth: moment(
      account?.patientDetails?.dateOfBirth || '1990-01-01',
      'YYYY-MM-DD',
      true,
    ).format('MM/DD/YYYY'),
    gender: account.patientDetails?.gender || '',
    genderIdentity: account.patientDetails?.genderIdentity || '',
    phoneNumber: account.phoneNumber,
    preferredName: account.preferredName,
    preferredPronouns: account.preferredPronouns,
    ftHeight: inchesToFeetAndInches(account.patientDetails?.height || 0).feet || NaN,
    inHeight: inchesToFeetAndInches(account.patientDetails?.height || 0).inches || NaN,
    weight: account.patientDetails?.weight || NaN,
    eFirstName: account.patientDetails?.emergencyContactFirstName || '',
    eLastName: account.patientDetails?.emergencyContactLastName || '',
    ePhoneNumber: account.patientDetails?.emergencyContactPhoneNumber || '',
  });

  const initialAccDetailsRef = React.useRef(accDetails);

  React.useEffect(() => {
    if (someFieldsNeeded) {
      handleAccountUpdate('DONT_UPDATE');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [someFieldsNeeded]);

  React.useEffect(() => {
    setHasFieldsChange(!equals(accDetails, initialAccDetailsRef.current));
  }, [accDetails]);

  const handleAccountInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = event.target;

    setAccDetails(acc => ({
      ...acc,
      [name]: value,
    }));
  };

  const handleDateInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (!value.match('^[0-9/]*$')) {
      return;
    }

    setAccDetails(acc => ({
      ...acc,
      [name]: value
        .replace(/^(\d\d)(\d)$/g, '$1/$2')
        .replace(/^(\d\d\/\d\d)(\d+)$/g, '$1/$2')
        .replace(/[^\d/]/g, ''),
    }));
  };

  const handleAccountUpdate = async (status?: string) => {
    if (!validator.allValid() || (status && status === 'DONT_UPDATE')) {
      setErrors(errs => ({
        ...errs,
        firstName: !validator.fieldValid('firstName'),
        lastName: !validator.fieldValid('lastName'),
        email: !validator.fieldValid('email'),
        dateOfBirth: !validator.fieldValid('dateOfBirth'),
        gender: !validator.fieldValid('gender'),
        genderIdentity: !validator.fieldValid('genderIdentity'),
        preferredName: !validator.fieldValid('preferredName'),
        preferredPronouns: !validator.fieldValid('preferredPronouns'),
        ftHeight: !validator.fieldValid('ftHeight'),
        inHeight: !validator.fieldValid('inHeight'),
        weight: !validator.fieldValid('weight'),
        eFirstName: !validator.fieldValid('eFirstName'),
        eLastName: !validator.fieldValid('eLastName'),
        ePhoneNumber: !validator.fieldValid('ePhoneNumber'),
        idPhoto: !account.idImage,
      }));
      showValidationMessage(true);
      return;
    }

    const payload = {
      firstName: accDetails.firstName,
      lastName: accDetails.lastName,
      email: accDetails.email,
      preferredName: accDetails.preferredName,
      preferredPronouns: accDetails.preferredPronouns,
      patientDetails: {
        dateOfBirth: moment(accDetails.dateOfBirth, 'MM/DD/YYYY', true).format('YYYY-MM-DD'),
        gender: accDetails.gender as Sex,
        genderIdentity: accDetails.genderIdentity as Gender,
        height: convertHeightToInches(`${accDetails.ftHeight}ft${accDetails.inHeight || 0}in`),
        weight: parseInt(`${accDetails.weight}`, 10),
        emergencyContactFirstName: accDetails.eFirstName,
        emergencyContactLastName: accDetails.eLastName,
        emergencyContactPhoneNumber: accDetails.ePhoneNumber,
      },
    };

    onUpdateAccount(payload);
  };

  const convertHeightToInches = (height: string) => {
    const [feet, inches] = (height.match(/\d+/g) || []).map(Number);
    return feet * 12 + inches;
  };

  function inchesToFeetAndInches(heightInInches: number) {
    const feet = Math.floor(heightInInches / 12);
    const inches = heightInInches % 12;
    return { feet, inches };
  }

  return (
    <div className="w-full h-full relative space-y-6">
      <div className="flex flex-col gap-6 tablet:flex-row">
        <div className="flex-1">
          <Input
            name="firstName"
            placeholder="First name"
            value={accDetails.firstName}
            onChange={handleAccountInputChange}
            onBlur={() => {
              setErrors(errs => ({
                ...errs,
                firstName: !validator.check(accDetails.firstName, [
                  'required',
                  { min: 2 },
                  { regex: /^[A-Z._-\s]*$/i },
                ]),
              }));
            }}
            error={errors.firstName}
          />
          {validator.message(
            'firstName',
            accDetails.firstName,
            ['required', { min: 2 }, { regex: /^[A-Z._-\s]*$/i }],
            {
              messages: {
                required: 'First name is required.',
              },
              className: 'text-river-red text-left text-[13px] mt-1 ml-1',
            },
          )}
        </div>
        <div className="flex-1">
          <Input
            name="lastName"
            placeholder="Last name"
            value={accDetails.lastName}
            onChange={handleAccountInputChange}
            onBlur={() => {
              setErrors(errs => ({
                ...errs,
                lastName: !validator.check(accDetails.lastName, [
                  'required',
                  { regex: /^[A-Z._-\s]*$/i },
                ]),
              }));
            }}
            error={errors.lastName}
          />
          {validator.message(
            'lastName',
            accDetails.lastName,
            ['required', { regex: /^[A-Z._-\s]*$/i }],
            {
              messages: {
                required: 'Last name is required.',
              },
              className: 'text-river-red text-left text-[13px] mt-1 ml-1',
            },
          )}
        </div>
      </div>

      <div className="flex flex-col gap-6 tablet:flex-row">
        <div className="flex-1">
          <Input
            name="email"
            placeholder="Email"
            value={accDetails.email}
            type="email"
            onChange={handleAccountInputChange}
            onBlur={() => {
              setErrors(errs => ({
                ...errs,
                email: !validator.check(accDetails.email, 'required|email'),
              }));
            }}
            error={errors.email}
          />
          {validator.message('email', accDetails.email, 'required|email', {
            messages: {
              required: 'Email address is required.',
            },
            className: 'text-river-red text-left text-[13px] mt-1 ml-1',
          })}
        </div>

        <div className="flex-1">
          <DateInput
            type={dateInputType}
            onChange={handleDateInputChange}
            value={accDetails.dateOfBirth}
            placeholder="Date of Birth"
            name="dateOfBirth"
            error={errors.dateOfBirth}
            onFocus={() => setDateInputType('date')}
            onBlur={() => {
              setErrors(errs => ({
                ...errs,
                dateOfBirth: !validator.check(
                  accDetails.dateOfBirth,
                  `required|date|dob:${accDetails.dateOfBirth}`,
                ),
              }));
              if (!accDetails.dateOfBirth) {
                setDateInputType('text');
              }
            }}
          />
          {validator.message(
            'dateOfBirth',
            accDetails.dateOfBirth,
            `required|date|dob:${accDetails.dateOfBirth}`,
            {
              messages: {
                required: 'Date of birth is required.',
              },
              className: 'text-river-red text-left text-[13px] mt-1 ml-1',
            },
          )}
        </div>
      </div>

      <div className="flex flex-col gap-6 tablet:flex-row">
        <div className="flex-1">
          <Select
            placeholder={{
              value: '',
              label: 'Sex at Birth',
            }}
            value={accDetails.gender}
            options={[
              {
                value: 'MALE',
                label: 'Male',
              },
              {
                value: 'FEMALE',
                label: 'Female',
              },
            ]}
            name="gender"
            onChange={handleAccountInputChange}
            onBlur={() => {
              setErrors(errs => ({
                ...errs,
                gender: !validator.check(accDetails.gender, 'required|string|min:3'),
              }));
            }}
            error={errors.gender}
          />
          {validator.message('gender', accDetails.gender, 'required|string|min:3', {
            messages: {
              required: 'Sex at Birth is required.',
            },
            className: 'text-river-red text-left text-[13px] mt-1 ml-1',
          })}
        </div>

        <div className="flex-1">
          <Select
            placeholder={{
              value: '',
              label: 'Gender Identity',
            }}
            value={accDetails.genderIdentity}
            options={[
              {
                value: 'MALE',
                label: 'Male',
              },
              {
                value: 'FEMALE',
                label: 'Female',
              },
              {
                value: 'TRANSGENDER-MALE',
                label: 'Transgender Male',
              },
              {
                value: 'TRANSGENDER-FEMALE',
                label: 'Transgender Female',
              },
              {
                value: 'TRANSGENDER',
                label: 'Transgender',
              },
              {
                value: 'NON-BINARY',
                label: 'Non-binary',
              },
            ]}
            name="genderIdentity"
            onChange={handleAccountInputChange}
            onBlur={() => {
              setErrors(errs => ({
                ...errs,
                genderIdentity: !validator.check(accDetails.genderIdentity, 'string|min:3'),
              }));
            }}
            error={errors.genderIdentity}
          />
          {validator.message('genderIdentity', accDetails.genderIdentity, 'string|min:3', {
            className: 'text-river-red text-left text-[13px] mt-1 ml-1',
          })}
        </div>
      </div>

      <div className="flex flex-col gap-6 tablet:flex-row">
        <div className="flex-1">
          <div className="flex flex-row space-x-4">
            <div className="flex flex-row items-center space-x-1">
              <Input
                name="ftHeight"
                placeholder="Height (In Feet)"
                value={`${accDetails.ftHeight}`}
                type="number"
                pattern="[0-9]*"
                inputMode="numeric"
                onChange={handleAccountInputChange}
                onBlur={() => {
                  setErrors(errs => ({
                    ...errs,
                    ftHeight: !validator.check(
                      accDetails.ftHeight,
                      'required|numeric|min:0,num|min:3,num|max:7,num',
                    ),
                  }));
                }}
                error={errors.ftHeight}
              />
              <span className="block font-medium">ft</span>
            </div>
            <div className="flex flex-row items-center space-x-1">
              <Input
                name="inHeight"
                placeholder="Height (In Inches)"
                value={`${accDetails.inHeight}`}
                type="number"
                pattern="[0-9]*"
                inputMode="numeric"
                onChange={handleAccountInputChange}
                onBlur={() => {
                  setErrors(errs => ({
                    ...errs,
                    inHeight: !validator.check(
                      accDetails.inHeight,
                      'required|numeric|min:0,num|min:0,num|max:11,num',
                    ),
                  }));
                }}
                error={errors.inHeight}
              />
              <span className="block font-medium">in</span>
            </div>
          </div>
          {validator.message(
            'ftHeight',
            accDetails.ftHeight,
            'required|numeric|min:0,num|min:3,num|max:7,num',
            {
              messages: {
                required: 'The height (ft) field is required.',
                min: 'The height (ft) must be at least 3 feet.',
                max: 'The height (ft) may not be greater than 7 feet.',
                numeric: 'The height (ft) must be a number.',
              },
              className: 'text-river-red text-left text-[13px] mt-1 ml-1',
            },
          )}
          {validator.message(
            'inHeight',
            accDetails.inHeight,
            'required|numeric|min:0,num|min:0,num|max:11,num',
            {
              messages: {
                required: 'The height (in) field is required.',
                min: 'The height (in) must be at least 0 inches.',
                max: 'The height (in) may not be greater than 11 inches.',
                numeric: 'The height (in) must be a number.',
              },
              className: 'text-river-red text-left text-[13px] mt-0 ml-1',
            },
          )}
        </div>
        <div className="flex-1">
          <Input
            name="weight"
            placeholder="Weight (In lbs)"
            value={`${accDetails.weight}`}
            type="number"
            pattern="[0-9]*"
            inputMode="numeric"
            onChange={handleAccountInputChange}
            onBlur={() => {
              setErrors(errs => ({
                ...errs,
                weight: !validator.check(
                  accDetails.weight,
                  'required|numeric|min:0,num|min:50,num',
                ),
              }));
            }}
            error={errors.weight}
          />
          {validator.message('weight', accDetails.weight, 'required|numeric|min:0,num|min:50,num', {
            messages: {
              min: 'The weight must be at least 50 lbs.',
            },
            className: 'text-river-red text-left text-[13px] mt-1 ml-1',
          })}
        </div>
      </div>

      <div className="flex flex-col gap-6 tablet:flex-row">
        <div className="flex-1">
          <Input
            name="preferredName"
            placeholder="Preferred name"
            value={accDetails.preferredName}
            onChange={handleAccountInputChange}
            onBlur={() => {
              setErrors(errs => ({
                ...errs,
                preferredName: !validator.check(accDetails.preferredName, [
                  { min: 2 },
                  { regex: /^[A-Z._-\s]*$/i },
                ]),
              }));
            }}
            error={errors.preferredName}
          />
          {validator.message(
            'preferredName',
            accDetails.preferredName,
            [{ min: 2 }, { regex: /^[A-Z._-\s]*$/i }],
            {
              className: 'text-river-red text-left text-[13px] mt-1 ml-1',
            },
          )}
        </div>

        <div className="flex-1">
          <Select
            placeholder={{
              value: '',
              label: 'Preferred Pronouns',
            }}
            value={accDetails.preferredPronouns || ''}
            options={[
              {
                value: 'HE/HIM/HIS',
                label: 'He/Him/His',
              },
              {
                value: 'SHE/HER/HERS',
                label: 'She/Her/Hers',
              },
              {
                value: 'THEY/THEM',
                label: 'They/Them',
              },
            ]}
            name="preferredPronouns"
            onChange={handleAccountInputChange}
            onBlur={() => {
              setErrors(errs => ({
                ...errs,
                preferredPronouns: !validator.check(accDetails.preferredPronouns, 'string|min:3'),
              }));
            }}
            error={errors.preferredPronouns}
          />
          {validator.message('preferredPronouns', accDetails.preferredPronouns, 'string|min:3', {
            className: 'text-river-red text-left text-[13px] mt-1 ml-1',
          })}
        </div>
      </div>

      <hr className="border-t-[1px] border-[#e9ecef] !mt-7 !mb-[14px]" />

      <div className="!my-0">
        <h3 className="font-semibold uppercase mb-2">EMERGENCY CONTACT</h3>

        <div className="w-full">
          <div className="grid grid-col-1 tablet:grid-cols-2 gap-6">
            <div>
              <Input
                name="eFirstName"
                placeholder="First name"
                value={accDetails.eFirstName}
                onChange={handleAccountInputChange}
                onBlur={() => {
                  setErrors(errs => ({
                    ...errs,
                    eFirstName: !validator.check(accDetails.eFirstName, [
                      'required',
                      { min: 2 },
                      { regex: /^[A-Z._-\s]*$/i },
                    ]),
                  }));
                }}
                error={errors.eFirstName}
              />
              {validator.message(
                'eFirstName',
                accDetails.eFirstName,
                ['required', { min: 2 }, { regex: /^[A-Z._-\s]*$/i }],
                {
                  messages: {
                    required: 'Emergency contact First name is required.',
                  },
                  className: 'text-river-red text-left text-[13px] mt-1 ml-1',
                },
              )}
            </div>

            <div>
              <Input
                name="eLastName"
                placeholder="Last name"
                value={accDetails.eLastName}
                onChange={handleAccountInputChange}
                onBlur={() => {
                  setErrors(errs => ({
                    ...errs,
                    eLastName: !validator.check(accDetails.eLastName, [
                      'required',
                      { min: 2 },
                      { regex: /^[A-Z._-\s]*$/i },
                    ]),
                  }));
                }}
                error={errors.eLastName}
              />
              {validator.message(
                'eLastName',
                accDetails.eLastName,
                ['required', { min: 2 }, { regex: /^[A-Z._-\s]*$/i }],
                {
                  messages: {
                    required: 'Emergency contact Last name is required.',
                  },
                  className: 'text-river-red text-left text-[13px] mt-1 ml-1',
                },
              )}
            </div>

            <div>
              <PhoneNumberInput
                id="ePhoneNumber"
                name="ePhoneNumber"
                placeholder="Phone number"
                value={accDetails.ePhoneNumber}
                error={errors.ePhoneNumber}
                onBlur={() => {
                  setErrors(errs => ({
                    ...errs,
                    ePhoneNumber: !validator.check(accDetails.ePhoneNumber, 'required|phone'),
                  }));
                }}
                onChange={value =>
                  setAccDetails(fndAccount => ({
                    ...fndAccount,
                    ePhoneNumber: value,
                  }))
                }
              />
              {validator.message('ePhoneNumber', accDetails.ePhoneNumber, 'required|phone', {
                messages: {
                  required: 'Emergency contact Phone number is required.',
                },
                className: 'text-river-red text-left text-[13px] mt-1 ml-1',
              })}
            </div>
          </div>
        </div>
      </div>

      <hr className="border-t-[1px] border-[#e9ecef] !mt-7 !mb-[14px]" />

      <div className="!my-0">
        <h3 className="font-semibold uppercase mb-2">UPLOAD ID</h3>

        <div>
          <div className="grid tablet:grid-cols-2 gap-6 w-full mx-auto mobile:mb-10">
            <button
              type="button"
              title={account.idImage ? 'View ID uploaded' : 'Click to upload ID'}
              onClick={() => onImageUpload('ID')}
              className={`border border-[#A7C8DA] bg-[#EDF9FF] rounded-xl p-5 flex flex-row justify-between 
                items-center ${
                  errors.idPhoto && !account.idImage ? '!dotted-border-error !border-none' : ''
                } `}
            >
              <div className="inline-flex items-center text-left">
                <Icon id="iconIdPhoto" className="w-10 mr-5 text-river-black fill-river-black" />
                <span>{account.idImage ? 'ID uploaded' : 'Click to upload ID Photo'}</span>
              </div>
              {account.idImage && (
                <button
                  type="button"
                  onClick={() => onImageUpload('ID')}
                  className="text-xs text-white bg-river-blue rounded-full px-[14px] py-[3px]"
                >
                  View
                </button>
              )}
            </button>
          </div>

          {errors.idPhoto && !account.idImage && (
            <div className="text-river-red text-left text-[13px] mt-1 ml-1">
              Please upload an ID Photo to complete your profile.
            </div>
          )}
        </div>
      </div>

      {/* Privacy Notice */}
      {isCompany && (
        <div className="mt-5 text-xs flex flex-row items-baseline -ml-3 leading-[22px]">
          <Icon id="asterisk" className="w-[13px] inline-flex mr-1" />
          <p>
            Changes to your personal information, including your address, are not shared with your
            employer. Contact your employer directly to update your employment information.
          </p>
        </div>
      )}

      <div className="text-center w-full tablet:text-left !mt-16 tablet:!mt-10">
        <Button
          label="Update Profile"
          onClick={handleAccountUpdate}
          className="disabled:!bg-[#C8CCCE] disabled:!text-white"
          disabled={!hasFieldsChange || isUpdating}
          loading={isUpdating}
        />
      </div>
    </div>
  );
}

export default AccountDetailsForm;
