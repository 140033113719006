/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useLocation } from 'react-router-dom';
import { useAuth, signOut, showAlert } from '../context/authContext';
import { paths } from '../routes';
import env from '../utils/env';

/**
 * Listens to App's incoming events. Like user idle state, no internet connections, etc
 * @param WrappedComponent
 * @returns
 */
const withAppListener = <P extends Record<string, unknown>>(
  WrappedComponent: React.ComponentType<P>,
) =>
  function (props: P) {
    const [, dispatch] = useAuth();
    const location = useLocation();

    /**
     *  APP IDLE STATE LISTENER
     */
    useIdleTimer({
      timeout: 1000 * 60 * 30,
      onIdle: () => signOut(dispatch),
      onActive: () => false,
      debounce: 250,
    });

    /**
     *  INTERCOM UPDATE LAST REQUEST DATE
     *  HIDE ON MESSAGES PAGE
     */
    React.useEffect(() => {
      window.Intercom('update', {
        last_request_at: parseInt((new Date().getTime() / 1000).toString(), 10),
        hide_default_launcher: true, // For now we are hiding intercom
      });
    }, [location?.pathname]);

    /**
     *  INTERNET CONNECTIVITY LISTENER
     */
    React.useEffect(() => {
      function internetConnectivityListener() {
        const condition = navigator.onLine ? 'online' : 'offline';

        if (condition === 'online') {
          const webPing = setInterval(() => {
            fetch(`${env('RIVER_API_URL')}/ping`)
              .then(() => {
                // Set state to `true` when user has internet

                showAlert(dispatch);

                return clearInterval(webPing);
              })
              .catch(() => {
                // Set state to `false` when user has internet

                showAlert(dispatch, {
                  show: true,
                  message: `Sorry! Looks like you are currently Offline. Could not connect to River!`,
                  type: 'ERROR',
                });
              });
          }, 2000);
          return;
        }

        // Set state to `false` when user has internet

        showAlert(dispatch, {
          show: true,
          message: `Sorry! Looks like you are currently Offline. Could not connect to River!`,
          type: 'ERROR',
        });
      }

      window.addEventListener('online', internetConnectivityListener);

      window.addEventListener('offline', internetConnectivityListener);

      return function cleanup() {
        window.removeEventListener('online', internetConnectivityListener);

        window.removeEventListener('offline', internetConnectivityListener);
      };
    }, [dispatch]);

    return <WrappedComponent {...props} />;
  };

export default withAppListener;
