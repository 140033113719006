import * as React from 'react';
import { equals } from 'ramda';
import useValidator from '../../../hooks/useValidator';
import { Input, Select, Button, Icon } from '../..';
import US_STATES from '../../../utils/usStates';
import { AuthUser } from '../../../context/authContext';
import { hasStateMatchingZipCode } from '../../../utils/getStateFromZipCode';

type Address = {
  street: string;
  apt: string;
  city: string;
  state: string;
  zipCode: string;
};

type AddressFormProps = {
  address: Address;
  isUpdating: boolean;
  isCompany: boolean;
  onUpdateAddress: (payload: Partial<AuthUser>) => void;
};

function AddressDetailsForm({ address, onUpdateAddress, isUpdating, isCompany }: AddressFormProps) {
  const [validator, showValidationMessage] = useValidator();
  const [errors, setErrors] = React.useState({
    street: false,
    apt: false,
    city: false,
    state: false,
    zipCode: false,
  });
  const [addressDetails, setAddressDetails] = React.useState<Address>(address);
  const [hasFieldsChange, setHasFieldsChange] = React.useState(false);

  const initialAddressDetailsRef = React.useRef(addressDetails);

  React.useEffect(() => {
    setHasFieldsChange(!equals(addressDetails, initialAddressDetailsRef.current));
  }, [addressDetails]);

  const handleAddressInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = event.target;

    setAddressDetails(add => ({
      ...add,
      [name]: value,
    }));
  };

  const handleAddressUpdate = async () => {
    if (!validator.allValid()) {
      setErrors(errs => ({
        ...errs,
        street: !validator.fieldValid('street'),
        apt: !validator.fieldValid('apt'),
        city: !validator.fieldValid('city'),
        state: !validator.fieldValid('state'),
        zipCode: !validator.fieldValid('zipCode'),
      }));
      showValidationMessage(true);
      return;
    }

    if (!hasStateMatchingZipCode(addressDetails.state, addressDetails.zipCode)) {
      setErrors(errs => ({
        ...errs,
        state: true,
        zipCode: true,
      }));
    } else {
      setErrors(errs => ({
        ...errs,
        state: false,
        zipCode: false,
      }));
    }

    const payload = {
      streetAddress: addressDetails.street,
      apartment: addressDetails.apt,
      city: addressDetails.city,
      state: addressDetails.state,
      zipCode: addressDetails.zipCode,
    };

    onUpdateAddress(payload);
  };

  return (
    <div className="h-full relative">
      <div className="grid tablet:grid-cols-2 gap-6 w-full mx-auto">
        <div className="tablet:col-span-2">
          <Input
            name="street"
            value={addressDetails.street}
            placeholder="Street address"
            onChange={handleAddressInputChange}
            onBlur={() => {
              setErrors(errs => ({
                ...errs,
                street: !validator.check(addressDetails.street, 'required|string|min:3'),
              }));
            }}
            error={errors.street}
          />
          {validator.message('street', addressDetails.street, 'required|string|min:3', {
            messages: {
              required: 'Street address is required.',
            },
            className: 'text-river-red text-left text-[13px] mt-1 ml-1',
          })}
        </div>
        <div>
          <Input
            name="apt"
            placeholder="Apt."
            value={addressDetails.apt}
            onChange={handleAddressInputChange}
          />
        </div>
        <div>
          <Input
            name="city"
            placeholder="City"
            value={addressDetails.city}
            onChange={handleAddressInputChange}
            onBlur={() => {
              setErrors(errs => ({
                ...errs,
                city: !validator.check(addressDetails.city, [
                  'required',
                  { min: 2 },
                  { regex: /^[A-Z._-\s]*$/i },
                ]),
              }));
            }}
            error={errors.city}
          />
          {validator.message(
            'city',
            addressDetails.city,
            ['required', { min: 2 }, { regex: /^[A-Z._-\s]*$/i }],
            {
              messages: {
                required: 'City is required.',
                regex: 'City cannot contain numbers.',
              },
              className: 'text-river-red text-left text-[13px] mt-1 ml-1',
            },
          )}
        </div>
        <div>
          <Select
            placeholder={{
              value: '',
              label: 'State',
            }}
            value={addressDetails.state}
            options={US_STATES}
            name="state"
            onChange={handleAddressInputChange}
            onBlur={() => {
              setErrors(errs => ({
                ...errs,
                state: !validator.check(addressDetails.state, 'required|string'),
              }));
            }}
            error={errors.state}
          />
          {validator.message('state', addressDetails.state, 'required|string', {
            messages: {
              required: 'State is required.',
            },
            className: 'text-river-red text-left text-[13px] mt-1 ml-1',
          })}
        </div>
        <div>
          <Input
            name="zipCode"
            placeholder="Zip code"
            value={addressDetails.zipCode}
            onChange={handleAddressInputChange}
            onBlur={() => {
              setErrors(errs => ({
                ...errs,
                zipCode: !validator.check(addressDetails.zipCode, 'required|numeric|min:3|max:5'),
              }));
            }}
            error={errors.zipCode}
          />
          {validator.message('zipCode', addressDetails.zipCode, 'required|numeric|min:3|max:5', {
            messages: {
              required: 'Zip code is required.',
            },
            className: 'text-river-red text-left text-[13px] mt-1 ml-1',
          })}
        </div>
      </div>

      {/* Privacy Notice */}
      {isCompany && (
        <div className="mt-5 text-xs flex flex-row items-baseline -ml-3 leading-[22px]">
          <Icon id="asterisk" className="w-[13px] inline-flex mr-1" />
          <p>
            Changes to your personal information, including your address, are not shared with your
            employer. Contact your employer directly to update your employment information.
          </p>
        </div>
      )}

      <div className="text-center w-full tablet:text-left !mt-16 tablet:!mt-10">
        <Button
          label="Update Address"
          onClick={handleAddressUpdate}
          className="disabled:!bg-[#C8CCCE] disabled:!text-white"
          disabled={!hasFieldsChange || isUpdating}
          loading={isUpdating}
        />
      </div>
    </div>
  );
}

export default AddressDetailsForm;
